<template>
<div>
  <!-- roomRTC -->
  <component
    :is="$app.useRtcComponent"
    v-if="roomId"
    ref="mainRoom"
    :user="user"
    :room="{id:roomId, video:true}"
    @usersChange="rtcUsersChanged"
    :userConfig="{'settings':getSavedDeviceSettings()}"
  />
  <!-- Video Layout (Browsing) -->
  <div v-if="showBrowsing">
    Audience Room
    <div class="alert-info form-inline sub-mt-2 sub-mr-2" v-if="$debug.isOn">
      <div>gridNumColumns:</div><b-form-input size="sm" v-model="gridNumColumns"/>
      <div>gridNumRows:</div><b-form-input size="sm" v-model="gridNumRows"/>
    </div>
    <video-layout 
      layout="row-wrap"
      :config="{numColumns:gridNumColumns, volumeMuted:true, showNote:true, numRows:gridNumRows}" 
      :users="audienceUsers"
      @page-change="onAudiencePageChange">
      <template slot-scope="{user}">
        <b-button size="sm" @click="setUserOnStage(user)" tooltip="Stage" class="mr-2">
          <font-awesome-icon icon="sun" :style="{ color: user.stage ? 'yellow' : 'white' }" size="sm"/>
        </b-button>
        <b-button size="sm" @click="setUserOnSpotlight(user)" tooltip="Spotlight">
          <font-awesome-icon icon="star" :style="{ color: user.spotlight ? 'yellow' : 'white' }" size="sm"/>
        </b-button>
      </template>
    </video-layout>
  </div>
  <!-- Video Layout (Mobile) -->
  <div v-else-if="isMobile">
    <video-layout 
      layout="row-wrap"
      :config="{numColumns:1, volumeMuted:true, showNote:true}" 
      :users="stageUsers"/>
    <video-layout 
      v-if="showGrid"
      layout="row-wrap"
      :config="{numColumns:4, volumeMuted:true, labelSize:'small'}" 
      :users="gridUsers"/>
  </div>
  <!-- Video Layout (Desktop) -->
  <div class="d-flex" v-else>
    <video-layout 
      layout="row"
      :config="{forceDisplay: stageUsers.length > 1 ? '4by3' : undefined, numColumns:stageUsers.length, volumeMuted:true, showNote:true}" 
      :users="stageUsers"/>
    <video-layout 
      v-if="showGrid"
      class="w-25"
      layout="row-wrap"
      :config="{numColumns:2, numRows:5, volumeMuted:true, labelSize:'small'}" 
      :users="gridUsers"/>
  </div>
  <!-- Audience specific -->
  <div v-if="showPreview">
    <component
      :is="$app.useRtcComponent"
      v-if="audienceRoomId"
      ref="audienceRoom"
      :user="user"
      :room="{id:audienceRoomId, video:true, focusedStreams:true}"
      @usersChange="audienceUsersChanged"
      :userConfig="{'settings':{definition:'low'}, bitrateMax:100}"
    />
  </div>
  <!-- Audio Out -->
  <audio-out
    :users="stageUsers"/>
  <!-- Room Controls -->
  <room-controls/>
  <div>
    <div>Role: {{ eventUser.role }}</div>
    <div>stageUsers.length: {{ stageUsers.length }}</div>
    <b-form-checkbox v-model="showB" @change="updateTestLayout();">Show B</b-form-checkbox>
    <b-form-checkbox v-model="showGrid">Show Grid</b-form-checkbox>
    <b-form-checkbox v-model="showBrowsing">Show Browsing</b-form-checkbox>
    <b-form-checkbox v-model="showPreview">Show Preview</b-form-checkbox>
  </div>
</div>
</template>

<script>
/*
Event Room (Room V2)
New form Room V1
 * Merged Users (WebRTC + eventUser)
 * Different Controls per Users (Not from Room)
*/
import { getLog } from "@/services/log";
let log = getLog("event-room", true);
import roomRTC from "@/components/roomRTC";
import VideoLayout from '@/components/videoLayout.vue';
import AudioOut from '@/components/audioOut.vue';
import roomControls from '@/components/roomControls';
import { randomString, getBrowser, leftJoin } from '../services/utils';
import { getSavedDeviceSettings } from '../services/mediautils';
import { updateEventUser } from '../services/event-manager';

export default {
  components: {
    roomRTC,
    VideoLayout,
    AudioOut,
    roomControls,
  },
  props: {
    roomId: String,
    user: Object,
    event: Object,
    eventUser: Object,
    eventUsers: Array,
    config: {
      type: Object,
      default: () => { return {}; }
    } 
  },
  data() {
    return {
      stageUsers: [],
      gridUsers: [],
      allUsers: [],
      showB: true,
      showGrid: true,
      isMobile: getBrowser().startsWith('mobile'),
      showBrowsing: true,
      showEmailCapture: true,
      showPreview: false,

      gridNumColumns: 4,
      gridNumRows: 4,

      rtcUsers: [],
      users: [],
      audienceUsers: [],
    };
  },
  computed: {
    audienceRoomId() {
      return this.event?.audienceRoomId;
    }
  },
  watch: {
    eventUsers() {
      this.updateUsersListUsers();
    }
  },  
  mounted() {
    log.log("mounted");
  },
  methods: {
    getSavedDeviceSettings,
    cloneFirstUser(users, count) {
      let user = users[0];
      users = [];
      for (let i = 0; i < count; ++i)
        users.push(Object.assign(Object.assign({}, user), {name:randomString(16), note:"dummy"}));
      return users;
    },
    rtcUsersChanged(users) {
      log.log("rtcUsersChanged", users);
      this.rtcUsers = users;
      this.updateUsersListUsers();
    },
    audienceUsersChanged(users) {
      log.log("audienceUsersChanged", users);
      this.audienceUsers = leftJoin(users, this.eventUsers || [], "id", "id");
    },
    onAudiencePageChange(focusedUsers) {
      this.$refs.audienceRoom.focusUsers(focusedUsers);
    },
    updateUsersListUsers() {
      log.log("updateUsersListUsers", this.rtcUsers, this.eventUsers);
      this.users = leftJoin(this.rtcUsers, this.eventUsers || [], "id", "id");
      this.updateTestLayout();
    },
    updateTestLayout() {
      if (this.testMode) {
        this.stageUsers = this.cloneFirstUser(this.users, this.showB ? 2 : 1);
        this.gridUsers = this.cloneFirstUser(this.users, 8);
        this.allUsers = this.cloneFirstUser(this.users, 32);
      } else {
        this.stageUsers = this.users.filter(u => u.stage);
        this.gridUsers = this.users.filter(u => u.spotlight);
        this.allUsers = this.users;
      }
    },
    setUserOnStage(eu) {
      log.log("setUserOnStage", eu.stage);
      updateEventUser(this.event.id, eu.id, {stage:!eu.stage});
    },
    setUserOnSpotlight(eu) {
      log.log("setUserOnSpotlight", eu.spotlight);
      updateEventUser(this.event.id, eu.id, {spotlight:!eu.spotlight});
    },
    sup(user) {
      log.log("sup", user);
    },
  }
}
</script>

<style>

</style>