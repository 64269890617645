<template>
<div>
  <b-tabs pills card vertical>
    <b-tab title="General" active>
      <b-card-text>
        <div class="form-inline sub-mt-2 sub-mr-2">
          <div>Entrance:</div>
          <b-form-select inline size="sm" v-model="workflowOptions.entranceMode" :options="entranceModeOptions"/>
        </div>
        <div class="form-inline sub-mt-2 sub-mr-2">
          <b-form-checkbox v-model="workflowOptions.useEventRoom">Use Event Room</b-form-checkbox> <b-badge>debug</b-badge>
        </div>
      </b-card-text>
    </b-tab>
    <!-- disabled -->
    <b-tab title="Access" v-if="!hideWIP">
      <b-card-text>
        <div class="form-inline sub-mt-2 sub-mr-2">
          <div>Entrance:</div>
          <b-form-select inline size="sm" v-model="entranceMode" :options="entranceModeOptions"/>
        </div>
        Invitation<br/>
        Moderation<br/>
      </b-card-text>
    </b-tab>
    <b-tab title="Content" v-if="!hideWIP">
      <b-card-text>
        Layout<br/>
        Screen Sharing<br/>
      </b-card-text>
    </b-tab>
    <b-tab title="Audience" v-if="!hideWIP">
      <b-card-text>
        Reactions<br/>
        Chat<br/>
        Video Q&;A<br/>
        Breakout Rooms<br/>
      </b-card-text>
    </b-tab>
    <b-tab title="Recording" v-if="!hideWIP">
      <b-card-text>
        Record<br/>
        Template<br/>
        Streaming<br/>
        Publishing<br/>
      </b-card-text>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("event-settings");
import { updateEvent } from "@/services/event-manager";

export default {
  props: {
    event: Object,
  },
  data() {
    return {
      hideWIP: true,

      workflowOptions: {},
      entranceModeOptions: [
        {text:"Opened (Everyone gets in)", value:null},
        {text:"Waiting Room (Users are asked to wait until pulled in)", value:'waitingRoom'},
        {text:"Office Hours (Users are asked to add an agendar until pulled for 1:1)", value:'queue'},
        {text:"Closed (Users are met with a screen and CTA)", value:'closed'},
      ],
    };
  },
  watch: {
    'event.workflowOptions': {
      handler(value) {
        this.workflowOptions = value;
      }
    }
  },
  beforeMount() {
    this.workflowOptions = this.event.workflowOptions;
    log.log("workflowOptions", this.workflowOptions);
  },
  methods: {
    save() {
      let data = {workflowOptions:this.workflowOptions};
      log.log("Saving Settings", data);
      updateEvent(this.event, data);
    }
  }
}
</script>

<style>

</style>