<template>
<div class="w-100">
  <!-- Classic Room -->
  <room
    v-if="!event.workflowOptions.useEventRoom"
    :roomId="params.roomId"
    :user="user"
    :roomUsers="eventUsersIds"
    :userOptions="{canEditQueue, canEditRoom, event, eventUser}"
    @roomJoined="roomJoined"
    @roomLeft="roomLeft"
    />
  <!-- Event Room -->
  <event-room
    v-else
    :roomId="params.roomId"
    :event="event"
    :user="user"
    :eventUser="eventUser"
    :eventUsers="eventUsers"
    :userOptions="{canEditQueue, canEditRoom}"
    @roomJoined="roomJoined"
    @roomLeft="roomLeft">
  </event-room>
  <!-- Meeting Actions -->
  <div class="ml-2 form-inline sub-mt-2 sub-mr-2">
    <button v-if="event.workflowOptions.entranceMode == 'queue'" class="btn btn-primary" @click="editQueue">Edit Queue</button>
    <b-button v-if="isHost" variant="primary" v-b-modal.modal-settings>Meeting Settings</b-button>
  </div>
  <div class="ml-2 form-inline sub-mt-2 sub-mr-2" v-if="false">
    <button class="btn btn-primary" @click="invite">Invite</button>
    <button class="btn btn-primary" @click="record">Record</button>
    <button class="btn btn-primary" @click="breakoutRooms">Rooms</button>
    <button class="btn btn-primary" @click="chat">Chat</button>
    <button class="btn btn-primary" @click="react">React</button>
    <button class="btn btn-primary" @click="prompt">Prompt</button>
  </div>
  <!-- Modals -->
  <b-modal id="modal-settings" title="Meeting Settings" 
    @ok="$refs.eventSettings.save();"
    scrollable size="xl" centered>
    <event-settings ref="eventSettings" :event="event"/>
  </b-modal>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("meeting");
import { db } from "@/services/db";
import eventRoom from '@/components/eventRoom.vue';
import room from '@/components/room.vue';
import { chatSendStatus } from "@/services/chat";
import eventSettings from '@/components/eventSettings.vue';

export default {
  components: {
    room,
    eventRoom,
    eventSettings 
  },
  props: {
    event: Object,
    eventUser: Object,
    params: Object,
    user: Object,
  },
  data() {
    return {
      eventUsers: [],
    }
  },
  computed: {
    eventUsersIds() {
      return this.eventUsers?.map(e => e.id) || null;
    },
    canEditQueue() {
      return this.event.workflowOptions.entranceMode == 'queue' && this.eventUser && this.eventUser.role == 'host';
    },
    canEditRoom() {
      return this.eventUser && this.eventUser.role == 'host';
    },
    isHost() {
      return this.eventUser && this.eventUser.role == 'host';
    },
  },
  mounted() {
    this.$app.router.disableContainer = true;
    this.$bind("eventUsers", db.collection(`LiveEvents/${this.event.id}/users`));
  },
  beforeDestroy() {
    log.log("beforeDestroy");
    this.$app.router.disableContainer = false;
  },
  methods: {
    editQueue() {

    },
    settings() {

    },
    invite() {

    },
    record() {

    },
    breakoutRooms() {

    },
    chat() {

    },
    react() {

    },
    prompt() {

    },
    async roomJoined() {
      chatSendStatus(this.user.id, "Joined room.", true);
    },
    async roomLeft(params) {
      log.log("roomLeft", params);
      chatSendStatus(this.user.id, "Left room.", true);
      await this.$parent.eventUserBehavior.on("roomLeft", params);
    },
  }
}
</script>

<style>

</style>